<!-- 一大四小 -->
<template>
  <div class="oneBigFourSmall">
    <div class="titleBox flex-center-between">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc}}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="">
      </div>
    </div>
    <div class="videoBox">
      <HorizontalVideoBox
          v-if="(isAcg?section.allMediaInfo:section.allVideoInfo)"
          :isAcg="isAcg"
          :currentIndex="0"
          :videoList="(isAcg?section.allMediaInfo:section.allVideoInfo)"
          :videoInfo="(isAcg?section.allMediaInfo:section.allVideoInfo)[0]"
          :key="(isAcg?section.allMediaInfo:section.allVideoInfo)[0].id"
          :isPlayCount="true"
      />
      <div class="fourGrid" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo)">
        <HorizontalVideoBox
            class="videoInfo"
            :isAcg="isAcg"
            :currentIndex="index"
            :videoInfo="item"
            :videoList="(isAcg?section.allMediaInfo:section.allVideoInfo)"
            v-for="(item,index) in (isAcg?section.allMediaInfo:section.allVideoInfo).slice(1, 5)"
            :key="item.id"
        />
      </div>
    </div>
    <div class="btnBoxs" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo) && isBtn">
      <div class="btnBox">
        <div class="btn" @click="moreBtn">
          <img class="icon" src="@/assets/png/moreVideoIcon.png" alt="">
          <span>更多片源</span>
        </div>
        <div class="halvingLine">

        </div>
        <div class="btn" @click="changeVideo">
          <img class="icon" src="@/assets/png/switchIcon.png" alt="">
          <span>更换一批</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import {jumpAdv} from "@/utils/getConfig";
import {queryChangeVideoList} from "@/api/app";
import {Toast} from "vant";

export default {
  name: "FourGridVertical",
  components: {
    HorizontalVideoBox
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn:{
      type: Boolean,
    }
  },
  methods: {
    moreBtn(){
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
    async changeVideo() {
      let res = await this.$Api(queryChangeVideoList, this.section.sectionID);
      const list = res.data.videos || [];
      if(list.length > 0){
        if(this.isAcg){
          this.section.allMediaInfo = list;
        }else{
          this.section.allVideoInfo = list;
        }
      }else{
        Toast('没有更多数据了~');
      }
    }
  }
}
</script>
<style scoped lang="scss">
.oneBigFourSmall {
  margin-top: 20px;

  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox{
      display: flex;
      flex-direction: column;

      .title{
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc{
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }

    }

    .rightBtn{
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon{
        width: 20px;
        height: 20px;
      }
    }
  }

  .videoBox {
    padding: 0 10px;
    margin-top: 12px;

    .fourGrid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 7px;

      .videoInfo {
        width: 174px;
        margin-top: 8px;
      }
    }
  }

  .btnBoxs{
    width: 100%;
    height: 38px;
    padding: 0 21px;
    margin-top: 16px;

    .btnBox{
      width: 333px;
      height: 38px;
      background: #F0F0F3;
      border-radius: 20px;
      box-shadow: -5px -5px 20px 0px #FFF, 5px 5px 20px 0px rgba(174, 174, 192, 0.50);

      display: flex;
      align-items: center;
      justify-content: center;

      .halvingLine{
        width: 1px;
        height: 38px;
        background: #CBCED1;
        margin: 0 45px;
      }

      .btn{
        display: flex;
        align-items: center;
        color: #B09FD9;
        font-size: 10px;
        font-weight: 900;

        .icon{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  /deep/ .horizontalVideoBox .coverBox {
    width: 355px;
    .cover{
      height: 201px;
    }
  }

  /deep/ .fourGrid .horizontalVideoBox .coverBox {
    width: 173px;
    .cover{
      height: 98px;
    }
  }
}
</style>
