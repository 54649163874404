<!-- 情色电影 -->
<template>
  <div class="eroticMovies">
    <ImgDecypt
      class="sectionBg"
      :src="section.sectionCover"
      :key="section.sectionCover"
    />
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          <img class="icon" src="@/assets/png/18forbidIcon.png" alt="" />
          <span>{{ section.sectionName }}</span>
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc }}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
      </div>
    </div>
    <div
      class="rowsBox"
      v-if="isAcg ? section.allMediaInfo : section.allVideoInfo"
      @touchstart.stop
      @touchmove.stop
      @touchend.stop
    >
      <HorizontalVideoBox
        class="videoBox"
        :currentIndex="index"
        :isAcg="isAcg"
        :videoInfo="item"
        :videoList="isAcg ? section.allMediaInfo : section.allVideoInfo"
        v-for="(item, index) in isAcg
          ? section.allMediaInfo
          : section.allVideoInfo"
        :key="item.id"
      />
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import { jumpAdv } from "@/utils/getConfig";
export default {
  name: "EroticMovies",
  components: {
    HorizontalVideoBox,
    ImgDecypt,
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
  },
  methods: {
    moreBtn() {
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.eroticMovies {
  margin-top: 18px;
  position: relative;
  background: rgba(176, 159, 217, 0.2);
  padding: 10px 0;

  .sectionBg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      flex-direction: column;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;

        .icon {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }

      .desc {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .rightBtn {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .rowsBox {
    margin-top: 12px;
    padding: 0 10px;
    display: flex;
    overflow-x: auto;
    margin-right: 5px;

    .videoBox {
      margin-right: 8px;
    }
  }
}

/deep/ .horizontalVideoBox {
  .coverBox {
    width: 113px;
    .cover {
      height: 166px;
    }
  }
}
</style>
