<!-- 短剧轮播 -->
<template>
  <div class="playletSwipe">
    <div class="swiperBox" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo) && (isAcg?section.allMediaInfo:section.allVideoInfo).length">
      <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
        <van-swipe-item
            v-for="(item, index) in (isAcg?section.allMediaInfo:section.allVideoInfo)"
            :key="index"
            @click="goAdv(item)"
        >
          <ImgDecypt :src="isAcg?item.verticalCover:item.cover" :key="isAcg?item.verticalCover:item.cover" />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
  import ImgDecypt from "@/components/ImgDecypt/index.vue";
  import {jumpAdv} from "@/utils/getConfig";

  export default {
    name: "PlayletSwipe",
    components: {ImgDecypt},
    props:{
      section: {
        type: Object,
      },
      isAcg: {
        type: Boolean,
      },
      isBtn:{
        type: Boolean,
      }
    },
    methods:{
      goAdv(item) {
        jumpAdv(item);
      },
    }
  }
</script>

<style scoped lang="scss">
  .playletSwipe{
    margin-top: 20px;

    .swiperBox {
      padding: 0 10px;

      .swipe {
        width: calc(100vw - 21px);
        height: calc((100vw - 21px) / 2.398);
        border-radius: 8px;

        .van-swipe-item {
          width: 100%;
          color: #fff;
          font-size: 20px;
          line-height: 150px;
          text-align: center;
          background-color: rgb(43, 39, 72);
        }

        /deep/ .van-swipe__indicators {
          //left: auto;
          //right: 0;
          //align-items: center;
        }

        /deep/ .van-swipe__indicator {
          width: 9px;
          height: 3px;
          background-color: #666667;
          border-radius: 3px;
        }

        /deep/ .van-swipe__indicator--active {
          width: 9px;
          height: 3px;
          background-color: #FFFFFF !important;
          border-radius: 3px;
        }

        ///deep/ img {
        //  object-fit: fill !important;
        //}

        /deep/ .van-image__error{
          border-radius: 0;
        }
      }
    }
  }
</style>