<!-- 六宫格竖 -->
<template>
  <div class="sixGridVertical">
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc }}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
      </div>
    </div>
    <div
      class="videoBox"
      v-if="isAcg ? section.allMediaInfo : section.allVideoInfo"
    >
      <HorizontalVideoBox
        class="videoInfo"
        :currentIndex="index"
        :isAcg="isAcg"
        :videoInfo="item"
        :videoList="isAcg ? section.allMediaInfo : section.allVideoInfo"
        v-for="(item, index) in (isAcg
          ? section.allMediaInfo
          : section.allVideoInfo
        ).slice(0, 9)"
        :key="item.id"
      />
    </div>
    <div
      class="btnBoxs"
      v-if="(isAcg ? section.allMediaInfo : section.allVideoInfo) && isBtn"
    >
      <div class="btnBox">
        <div class="btn" @click="moreBtn">
          <img class="icon" src="@/assets/png/moreVideoIcon.png" alt="" />
          <span>更多片源</span>
        </div>
        <div class="halvingLine"></div>
        <div class="btn" @click="changeVideo">
          <img class="icon" src="@/assets/png/switchIcon.png" alt="" />
          <span>更换一批</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import PullRefresh from "@/components/PullRefresh/index.vue";
import { jumpAdv } from "@/utils/getConfig";
import { queryChangeVideoList } from "@/api/app";
import { Toast } from "vant";
export default {
  name: "SixGridVertical",
  components: {
    HorizontalVideoBox,
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
  },
  methods: {
    moreBtn() {
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
    async changeVideo() {
      let res = await this.$Api(queryChangeVideoList, this.section.sectionID);
      const list = res.data.videos || [];
      if (list.length > 0) {
        if (this.isAcg) {
          this.section.allMediaInfo = list;
        } else {
          this.section.allVideoInfo = list;
        }
      } else {
        Toast("没有更多数据了~");
      }
    },
  },
};

// /vid/section/changeVideo
</script>

<style scoped lang="scss">
.sixGridVertical {
  margin-top: 18px;
  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      flex-direction: column;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .rightBtn {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .videoBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    margin-top: 4px;
    .videoInfo {
      width: 112px;
      margin-top: 8px;
    }
    .cartoonInfo {
      margin-top: 8px;
    }
  }

  .btnBoxs {
    width: 100%;
    height: 38px;
    padding: 0 21px;
    margin-top: 16px;

    .btnBox {
      width: 333px;
      height: 38px;
      background: #f0f0f3;
      border-radius: 20px;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);

      display: flex;
      align-items: center;
      justify-content: center;

      .halvingLine {
        width: 1px;
        height: 38px;
        background: #cbced1;
        margin: 0 45px;
      }

      .btn {
        display: flex;
        align-items: center;
        color: #b09fd9;
        font-size: 10px;
        font-weight: 900;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
  /deep/ .horizontalVideoBox .coverBox {
    width: 113px;
    .cover {
      height: 166px;
    }
  }
}
</style>
