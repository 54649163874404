<template>
  <div class="JustLook">
    <div class="videoItem">
      <!--      <div class="titleBar">-->
      <!--        <div>-->
      <!--          <p class="titleName">{{ section.sectionName }}</p>-->
      <!--          <p class="titleDetails">{{ section.sectionDesc }}</p>-->
      <!--        </div>-->
      <!--        <div class="moreRight" @click="moreMore">-->
      <!--          <span class="moreRightTitle">更多</span>-->
      <!--          <img src="@/assets/png/moreRight.png" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="titleBox">
        <div class="leftBox">
          <div class="title">
            {{ section.sectionName }}
          </div>
          <div class="desc" v-if="section.sectionDesc">
            {{ section.sectionDesc }}
          </div>
        </div>
        <div class="rightBtn" @click="moreMore" v-if="!flag">
          <span>更多</span>
          <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
        </div>
      </div>
      <div class="videoInfo">
        <PullRefresh
          :disabled="isRefreshDisable"
          :loading="loading"
          :finished="finished"
          @onLoad="onLoad"
          :isNoData="isNoData"
          :error="error"
          :hasAdvPagination="true"
          :offset="-63"
        >
          <div class="videoBox" v-if="flag">
            <HorizontalVideoBox
              class="videoInfo"
              :currentIndex="index"
              :isAcg="isAcg"
              :videoInfo="item"
              :videoList="videoList"
              v-for="(item, index) in videoList"
              :key="item.id"
            />
          </div>
          <div class="videoBox" v-else>
            <HorizontalVideoBox
              class="videoInfo"
              :currentIndex="index"
              :isAcg="isAcg"
              :videoInfo="item"
              :videoList="section.allMediaInfo"
              v-for="(item, index) in section.allMediaInfo.slice(0, 6)"
              :key="item.id"
            />
          </div>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import PullRefresh from "@/components/PullRefresh/index.vue";
import { jumpAdv } from "@/utils/getConfig";
import { getJustLookList } from "@/api/home";
import SixGridVertical from "@/components/SixGridVertical/index.vue";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import { queryAcgMediaList } from "@/api/video";

export default {
  name: "JustLook",
  components: {
    HorizontalVideoBox,
    // SixGridVertical,
    PullRefresh,
    // VerticleThreeList,
  },
  props: {
    allData: {
      type: Object,
    },
    section: {
      type: Object,
    },
    list: {
      type: Array,
    },
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      loading: false,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      flag: false,
      isAcg: true,
    };
  },
  computed: {
    updatedItems() {
      const lastItem = this.list[this.list.length - 1];
      return lastItem && lastItem.showType == 28;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.allData.hasNext) {
        this.allData.allSection.map((item, index) => {
          if (item.showType && index + 1 === this.allData.allSection.length) {
            this.flag = true;
          }
        });
        // debugger;
        if (this.flag) {
          this.loading = true;
          this.queryJustLookList();
        }
        console.log(this.flag, this.allData, this.list, this.section);
      }
    },
    onLoad() {
      if (this.flag) {
        if (!this.allData.hasNext) {
          this.loading = true;
          if (this.error) {
            this.error = false;
          } else {
            this.pageNumber++;
          }
          this.queryJustLookList();
        }
      }
    },
    // onRefresh() {
    //  this.pageNumber = 1;
    //  this.refreshing = true;
    //  this.queryJustLookList();
    // },
    async queryJustLookList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        // moduleID: this.section.sectionID,
        sortType: "1",
        sId: this.section.sectionID,
      };
      let res = await this.$Api(queryAcgMediaList, req);
      console.log(res, "随便看看");
      this.loading = false;
      this.refreshing = false;
      const list = res.data.list || [];
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
       // this.pageNumber = 0;
      }
    },
    moreMore() {
      // this.$router.push({
      //   path: "topicPage",
      //   query: {
      //     topicId: this.videoInfoData.id,
      //     zone: this.videoInfoData.zone,
      //     name: this.videoInfoData.name,
      //     format: 0,
      //   },
      // });
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.JustLook {
  /deep/ .van-list__finished-text {
    display: none;
  }
  .videoItem {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 12px;
    margin-top: 12px;

    .titleBox {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftBox {
        display: flex;
        flex-direction: column;

        .title {
          color: #333333;
          font-size: 18px;
          font-weight: 600;
        }

        .desc {
          font-size: 12px;
          color: #999;
          font-weight: 400;
          margin-top: 10px;
        }
      }

      .rightBtn {
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 12px;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    //.titleBar {
    //  padding: 0 10px;
    //  display: flex;
    //  justify-content: space-between;
    //  align-items: center;
    //
    //  .titleName {
    //    font-size: 18px;
    //    color: #000000;
    //    font-weight: bold;
    //  }
    //
    //  .titleDetails {
    //    font-size: 12px;
    //    color: #999999;
    //  }
    //
    //  .moreRight {
    //    color: #000000;
    //
    //    .moreRightTitle {
    //      font-size: 14px;
    //      margin-right: 6.1px;
    //    }
    //
    //    img {
    //      width: 12px;
    //      height: 12px;
    //    }
    //  }
    //}

    .videoInfo {
      /deep/ .isNoData {
        display: none;
      }
      .videoBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        margin-top: 4px;
        .videoInfo {
          width: 112px;
          margin-top: 8px;
          /deep/ .cover {
            height: 166px;
          }
        }
        .cartoonInfo {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
