<!-- 企业影片 -->
<template>
  <div class="domesticMulitTopic">
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc }}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
      </div>
    </div>
    <div class="tags" @touchstart.stop @touchmove.stop @touchend.stop>
      <div
        class="tagBox"
        :class="activeIndex === index ? 'selected' : ''"
        :id="item.id"
        @click="selectBrand(index)"
        v-for="(item, index) in tagList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="my-swipe" ref="swiperContainer" @touchstart.stop @touchmove.stop @touchend.stop>
      <div
        class="swiperSlide"
        v-for="(arr, index) in tagList"
        :key="index"
        ref="swiperSlide"
      >
        <div class="contentBox" v-if="arr.allMediaInfo">
          <div
            class="rowsBox"
            v-for="(item, index) in arr.allMediaInfo.slice(0, 4)"
            :key="item.id"
            @click="openDetails(arr.allMediaInfo.slice(0, 4), item, index)"
          >
            <ImgDecypt
              class="cover"
              :src="item.verticalCover"
              :key="item.verticalCover"
            />
            <div class="infoBox">
              <div class="num">0{{ index + 1 }}</div>
              <div class="permission">
                <div class="label" v-if="item.permission == 1">
                  <img
                    class="icon"
                    src="@/assets/png/goldLabelIcon.png"
                    alt=""
                  />
                </div>
                <div class="label" v-if="item.permission == 2">
                  <img
                    class="icon"
                    src="@/assets/png/freeLabelIcon.png"
                    alt=""
                  />
                </div>
                <div class="label" v-if="item.permission === 0">
                  <img
                    class="icon"
                    src="@/assets/png/vipLabelIcon.png"
                    alt=""
                  />
                </div>
                <div class="actorName ellipsis2">{{ item.title }}</div>
              </div>
              <div v-if="item.tagDetails" class="tagsBox">
                <div
                  class="tag"
                  v-for="item in item.tagDetails.slice(0, 3)"
                  :key="item.id"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <van-swipe class="my-swipe" ref="mySwipe" :show-indicators="false" :width="300" :loop="false" @change="onChange">-->
    <!--      <van-swipe-item v-for="(arr, index) in tagList" :key="index">-->
    <!--        <div v-if="arr.allMediaInfo">-->
    <!--         <div class="rowsBox" v-for="item in arr.allMediaInfo.slice(0, 4)" :key="item.id" @click="jumpPage(item)">-->
    <!--          <ImgDecypt class="cover" :src="item.verticalCover" :key="item.verticalCover" />-->
    <!--          <div class="infoBox">-->
    <!--           <div class="actorName">{{ item.title }}</div>-->
    <!--           <div v-if="item.tagDetails">-->
    <!--            <div class="desc" v-for="item in item.tagDetails.slice(0, 3)" :key="item.id">-->
    <!--             {{ item.name }}-->
    <!--            </div>-->
    <!--           </div>-->
    <!--          </div>-->
    <!--         </div>-->
    <!--        </div>-->
    <!--      </van-swipe-item>-->
    <!--    </van-swipe>-->
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import { jumpAdv } from "@/utils/getConfig";
import { queryChangeVideoList } from "@/api/app";
import { setSessionItem } from "@/utils/longStorage";
import { openDetails } from "@/utils/getConfig";
export default {
  name: "HorizontalFourGrid",
  components: {
    ImgDecypt,
    // HorizontalVideoBox
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeIndex: 0,
      tagList: [],
    };
  },
  mounted() {
    this.tagList = this.section.allRankings || [];
    console.log(this.tagList);
    this.currentBrand = this.tagList[0];
    this.$refs.swiperContainer.addEventListener("scroll", this.onSlideChange);
    // this.videoInfo = this.currentBrand.allMediaInfo;
  },
  beforeDestroy() {
    this.$refs.swiperContainer.removeEventListener(
      "scroll",
      this.onSlideChange
    );
  },
  methods: {
    openDetails,
    moreBtn() {
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/hotRankingPage",
        });
      }
    },
    selectBrand(index) {
      this.activeIndex = index;
      this.scrollToBrand(index);
      // this.$refs.mySwipe.swipeTo(index);
    },
    scrollToBrand(index) {
      const swiperContainer = this.$refs.swiperContainer;
      const swiperSlides = this.$refs.swiperSlide;
      let scrollDistance = 0;

      for (let i = 0; i < index; i++) {
        scrollDistance += swiperSlides[i].getBoundingClientRect().width;
      }

      swiperContainer.scrollTo({
        left: scrollDistance,
        behavior: "smooth",
      });
    },
    onSlideChange() {
      const swiperContainer = this.$refs.swiperContainer;
      const swiperSlides = this.$refs.swiperSlide;
      let scrollLeft = swiperContainer.scrollLeft;
      let totalWidth = 0;

      for (let i = 0; i < swiperSlides.length; i++) {
        totalWidth += swiperSlides[i].getBoundingClientRect().width;
        if (scrollLeft + 16 < totalWidth) {
          this.activeIndex = i;
          break;
        }
      }
    },
    onChange(index) {
      this.activeIndex = index;
      let dom = document.getElementById(this.tagList[index].id);
      dom?.scrollIntoView({
        inline: "nearest",
        block: "nearest",
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.domesticMulitTopic {
  margin-top: 16px;
  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      flex-direction: column;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .rightBtn {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .tags {
    padding: 0 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    .tagBox {
      margin-right: 20px;
      font-size: 12px;
      font-weight: 900;
      line-height: normal;
      color: #999999;
      flex-shrink: 0;
      padding: 3px 8px;
    }
    .selected {
      font-size: 12px;
      font-weight: 900;
      color: #555555;
      background: #f0f0f3;
      padding: 3px 8px;
      border-radius: 8px;
      border: 2px solid rgba(0, 0, 0, 0.05);
    }
  }
  .my-swipe {
    //margin-left: 10px;
    padding-left: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    overflow-x: auto;
    overflow-y: hidden;
  }
  .swiperSlide {
    .contentBox {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: column;
      row-gap: 10px;
      //overflow-x: auto;
    }
  }
  .rowsBox {
    display: flex;
    //margin-left: 10px;
    margin-right: 16px;
    margin-top: 25px;
    width: 234px !important;
    height: 80px;
    background: #fff;
    border-radius: 5px;
    .cover {
      height: 89px;
      width: 61px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      margin-top: -10px;
    }
    .infoBox {
      padding-left: 12px;
      width: 228px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .num {
        font-size: 18px;
        font-weight: bolder;
        font-style: italic;
        line-height: 28px;
        color: #b09fd9;
      }
      .permission {
        position: relative;
        .label {
          position: absolute;
          top: -1px;
          left: 0;
          .icon {
            width: 32px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .actorName {
          text-indent: 3em;
          color: #000000;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 3px;
          line-height: 18px;
        }
      }
      .tagsBox {
        display: flex;
        justify-content: flex-start;
        .tag {
          padding-right: 6px;
          line-height: 18px;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
