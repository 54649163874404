<!-- 企业影片 -->
<template>
  <div class="domesticMulitTopic">
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc }}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
      </div>
    </div>
    <van-swipe
      class="my-swipe"
      ref="mySwipe"
      :show-indicators="false"
      @change="onChange"
    >
      <van-swipe-item
        v-for="(item, index) in tagList"
        :key="index"
        @click="openVideo(item.allVideoInfo[0])"
      >
        <div class="rowsBox">
          <div class="rowBox" :key="item.id">
            <div class="videoBox">
              <ImgDecypt
                class="cover"
                :src="item.allVideoInfo[0].cover"
                :key="item.allVideoInfo[0].cover"
              />
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="tags" @touchstart.stop @touchmove.stop @touchend.stop>
      <div
        class="tagBox"
        :class="activeIndex === index ? 'selected' : ''"
        :id="item.id"
        @click="selectBrand(index)"
        v-for="(item, index) in tagList"
        :key="index"
      >
        {{ item.tagName }}
      </div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import { jumpAdv } from "@/utils/getConfig";
import { queryChangeVideoList } from "@/api/app";
import { setSessionItem } from "@/utils/longStorage";
export default {
  name: "HorizontalFourGrid",
  components: {
    ImgDecypt,
    // HorizontalVideoBox
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeIndex: 0,
      tagList: [],
    };
  },
  mounted() {
    this.tagList = this.section.allTags || [];
    this.currentBrand = this.tagList[0];
    this.videoInfo = this.currentBrand.allVideoInfo[0];
  },
  methods: {
    moreBtn() {
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
    selectBrand(index) {
      this.activeIndex = index;
      this.$refs.mySwipe.swipeTo(index);
    },
    onChange(index) {
      this.activeIndex = index;
      let dom = document.getElementById(this.tagList[index].id);
      dom?.scrollIntoView({
        inline: "nearest",
        block: "nearest",
        behavior: "smooth",
      });
    },
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: "/videoDetailsPage",
          query: {
            id: item.id,
            kind: 0,
          },
        });
      } else {
        setSessionItem("currentWorks", JSON.stringify(item));
        this.$router.push({
          path: "/shortVideo",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.domesticMulitTopic {
  margin-top: 16px;
  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      flex-direction: column;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }
    }
    .rightBtn {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .tags {
    padding: 0 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    .tagBox {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      color: #777;
      flex-shrink: 0;
    }
    .selected {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      background: #b09fd9;
      padding: 3px 8px;
      border-radius: 8px;
      // border: 2px solid #e15f78;
    }
  }
  .my-swipe {
    //margin-left: 10px;
    padding-left: 10px;
  }
  .rowsBox {
    // max-height: 280px;
    margin-top: 12px;
    padding: 0 10px 0 0;
    // overflow-x: auto;
    // display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-column-gap: 5px;
    // grid-row-gap: 12px;
    .rowBox {
      .cover {
        height: 198px;
        width: 355px;
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }
}
</style>
