<template>
  <div class="fourGridLoadVertical">
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc }}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <span>更多</span>
        <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="" />
      </div>
    </div>
    <div class="main">
      <!--      <PullRefresh :params="moreParams" :offset="-73" @onLoad="onLoad">-->
      <PullRefresh
        :disabled="isRefreshDisable"
        :loading="loading"
        :finished="finished"
        @onLoad="onLoad"
        :isNoData="isNoData"
        :error="error"
        :hasAdvPagination="true"
        :offset="-73"
      >
        <div class="rowsBox">
          <!--          <HorizontalVideoBox-->
          <!--              class="videoBox"-->
          <!--              :videoInfo="item"-->
          <!--              :isAcg="isAcg"-->
          <!--              :currentIndex="index"-->
          <!--              :videoList="(isAcg?section.allMediaInfo:section.allVideoInfo)"-->
          <!--              v-for="(item,index) in (isAcg?section.allMediaInfo:section.allVideoInfo)"-->
          <!--              :key="item.id"-->
          <!--          />-->
          <!--          <HorizontalVideoBox-->
          <!--              class="videoBox"-->
          <!--              :videoInfo="item"-->
          <!--              :isAcg="isAcg"-->
          <!--              :currentIndex="index"-->
          <!--              :videoList="videoList"-->
          <!--              v-for="(item,index) in videoList"-->
          <!--              :key="item.id"-->
          <!--          />-->
          <template v-if="flag">
            <HorizontalVideoBox
              class="videoBox"
              :currentIndex="index"
              :isAcg="isAcg"
              :videoInfo="item"
              :videoList="videoList"
              v-for="(item, index) in videoList"
              :key="item.id"
            />
          </template>
          <template v-else>
            <HorizontalVideoBox
              class="videoBox"
              :currentIndex="index"
              :isAcg="isAcg"
              :videoInfo="item"
              :videoList="section.allMediaInfo"
              v-for="(item, index) in (isAcg
                ? section.allMediaInfo
                : section.allVideoInfo
              ).slice(0, 4)"
              :key="item.id"
            />
          </template>
        </div>
      </PullRefresh>
    </div>
    <!--    <div class="rowsBox" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo)">-->
    <!--      <HorizontalVideoBox-->
    <!--          class="videoBox"-->
    <!--          :videoInfo="item"-->
    <!--          :isAcg="isAcg"-->
    <!--          :currentIndex="index"-->
    <!--          :videoList="(isAcg?section.allMediaInfo:section.allVideoInfo)"-->
    <!--          v-for="(item,index) in (isAcg?section.allMediaInfo:section.allVideoInfo).slice(0,4)"-->
    <!--          :key="item.id"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div class="btnBoxs" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo) && isBtn">-->
    <!--      <div class="btnBox">-->
    <!--        <div class="btn"  @click="moreBtn">-->
    <!--          <img class="icon" src="@/assets/png/moreVideoIcon.png" alt="">-->
    <!--          <span>更多片源</span>-->
    <!--        </div>-->
    <!--        <div class="halvingLine">-->

    <!--        </div>-->
    <!--        <div class="btn" @click="changeVideo">-->
    <!--          <img class="icon" src="@/assets/png/moreVideoIcon.png" alt="">-->
    <!--          <span>更换一批</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import PullRefresh from "@/components/PullRefresh/index.vue";
import { queryChangeVideoList } from "@/api/app";
import { Toast } from "vant";
import { queryAcgMediaList, queryVideoMediaList } from "@/api/video";
export default {
  name: "FourGridLoadVertical",
  components: {
    HorizontalVideoBox,
    PullRefresh,
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
    allData: {
      type: Object,
    },
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: true,
      isLoading: true,
      loading: false,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      flag: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.allData.hasNext) {
        this.allData.allSection.map((item, index) => {
          if (
            item.sectionID === this.section.sectionID &&
            index + 1 === this.allData.allSection.length
          ) {
            this.flag = true;
          }
        });
        if (this.flag) {
          this.loading = true;
          this.getTopicMediaList();
        }
      }
    },
    onLoad() {
      // this.loading = true;
      // if (this.error) {
      //   this.error = false;
      // } else {
      //   this.pageNumber++;
      // }
      // this.getTopicMediaList('');
      if (this.flag) {
        if (!this.allData.hasNext) {
          this.loading = true;
          if (this.error) {
            this.error = false;
          } else {
            this.pageNumber++;
          }
          this.getTopicMediaList();
        }
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.getTopicMediaList("refresh");
    },
    async getTopicMediaList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortType: 1,
      };
      if (this.isAcg) {
        req["sId"] = this.section.sectionID;
      } else {
        req["sid"] = this.section.sectionID;
      }
      let res = null;
      if (this.isAcg) {
        res = await this.$Api(queryAcgMediaList, req);
      } else {
        res = await this.$Api(queryVideoMediaList, req);
      }
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      if (res && res.code === 200) {
        let list = res.data.list || [];
        if (type === "refresh") {
          this.videoList = list;
        } else {
          this.videoList = this.videoList.concat(list);
        }
        if (this.pageNumber === 1 && this.videoList.length === 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
        }
      }
    },
    moreBtn() {
      this.$router.push({
        path: "/topicPage",
        query: {
          topicId: this.section.sectionID,
          name: this.section.sectionName,
          isAcg: this.isAcg ? 1 : 0,
          patternType: this.patternType || 0,
        },
      });
    },
    async changeVideo() {
      let res = await this.$Api(queryChangeVideoList, this.section.sectionID);
      const list = res.data.videos || [];
      if (list.length > 0) {
        if (this.isAcg) {
          this.section.allMediaInfo = list;
        } else {
          this.section.allVideoInfo = list;
        }
      } else {
        Toast("没有更多数据了~");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fourGridLoadVertical {
  margin-top: 18px;

  /deep/ .isNoData {
    display: none;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox {
      display: flex;
      flex-direction: column;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc {
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .rightBtn {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .rowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 174px;
      margin-top: 12px;
    }
  }

  .btnBoxs {
    width: 100%;
    height: 38px;
    padding: 0 21px;
    margin-top: 16px;

    .btnBox {
      width: 333px;
      height: 38px;
      background: #f0f0f3;
      border-radius: 20px;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);

      display: flex;
      align-items: center;
      justify-content: center;

      .halvingLine {
        width: 1px;
        height: 38px;
        background: #cbced1;
        margin: 0 45px;
      }

      .btn {
        display: flex;
        align-items: center;
        color: #b09fd9;
        font-size: 10px;
        font-weight: 900;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
  /deep/ .horizontalVideoBox .coverBox {
    width: 173px;
    .cover {
      height: 254px;
    }
  }
  /deep/ .videoTitle {
    padding-bottom: 0 !important;
  }
}
</style>
