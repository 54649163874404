<template>
 <div class="originalSwiper adv-box">
  <swiper
    ref="swiper"
    class="swiper_box"
    :options="swiperOption"
    v-if="section.allMediaInfo.length > 0"
  >
   <swiper-slide
     class="swiperSlide"
     v-for="(item, index) in section.allMediaInfo"
     :key="index"
   >
    <img
      :src="place"
      class="advimg"
      @click="openDetails(section.allMediaInfo, item, index)"
      :data-item="JSON.stringify(item)"
      :key="index"
    />
   </swiper-slide>
  </swiper>
  <div class="bgBox">
   <ImgDecypt
     class="bgImage"
     :src="section.allMediaInfo[activeIndex].verticalCover"
     :key="activeIndex"
   />
  </div>
 </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { imgDecyptApi } from "@/api/app";
import { mapGetters } from "vuex";
import {jumpAdv, openDetails} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt";

export default {
 components: {
  swiper,
  swiperSlide,
  ImgDecypt,
 },
 props: {
  section: {
   type: Object,
  },
 },
 computed: {
  ...mapGetters({
   imgApi: "imgApi",
  }),
  swiper() {
   return this.$refs.swiper.swiper;
  },
  getIndex() {
   const swiper = this.$refs.swiper.swiper;
   return swiper.activeIndex;
  },
 },
 data() {
  return {
   activeIndex: 2,
   place: require("@/assets/png/plac_h.png"),
   // 轮播图配置
   swiperOption: {
    loop: true,
    pagination: {
     el: ".swiper-pagination",
     clickable: true,
     bulletActiveClass: "my-bullet-active",
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    effect: "coverflow",
    coverflowEffect: {
     rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
     stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
     depth: 60, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
     modifier: 3, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
     slideShadows: false, //开启slide阴影。默认 true。
    },
    slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
    initialSlide: 1,
    noSwiping: true,
    on: {
     slideChange: () => {
      this.getCurrentIndex();
     },
     // click: function (e) {
      // let item = JSON.parse(e.target.dataset.item);
      // console.log(item)
      // console.log(e.target.dataset.item)
      // jumpAdv(item);
     // },
    },
   },
  };
 },
 mounted() {
  let imgApi = this.imgApi;
  if (imgApi.slice(-1) != "/") {
   imgApi = imgApi + "/";
  }
  let dom = document.querySelectorAll(".adv-box .swiperSlide");
  let domlen = dom.length;
  for (let i = 0; i < domlen; i++) {
   let imgDom = dom[i].querySelector("img");
   imgDecyptApi(
     imgApi +
     this.section.allMediaInfo[
       dom[i].getAttribute("data-swiper-slide-index")
       ].verticalCover
   ).then((url) => {
    if (imgDom) {
     imgDom.src = url;
    }
   });
  }
 },
 methods: {
  openDetails,
  getCurrentIndex() {
   const swiper = this.$refs.swiper.swiper;
   let swiperIndex = swiper.activeIndex;
   if (swiper.activeIndex > this.section.allMediaInfo.length + 1) {
    this.activeIndex =
      swiper.activeIndex - (this.section.allMediaInfo.length + 2);
   } else {
    this.activeIndex = swiper.activeIndex - 2;
   }
  },
  /**
   * * 跳转广告
   *  * @param {*} item 广告信息
   */
  goAdv(item) {
   // console.log(item)
   jumpAdv(item);
  },
 },
};
</script>
<style lang="scss" scoped>
.advimg {
 width: 100%;
 height: 100%;
 text-align: center;
}

.originalSwiper {
 margin-top: 20px;
 padding: 20px 0px;
 position: relative;
 background-size: 100% 100%;
 background-repeat: no-repeat;

 /deep/ .swiper-pagination {
  bottom: 6px;
  left: auto;
  width: 100%;
  right: 20px;
  text-align: right;
  margin-right: 20px;
  z-index: 999;
 }

 /deep/ .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 2px 4px;
  background: linear-gradient($pumpkinOrange, $brownishOrange);
  opacity: 0.5;
 }

 /deep/ .my-bullet-active {
  background: linear-gradient($pumpkinOrange, $brownishOrange);
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
 }

 .swiper_box {
  margin-left: -30px;

  .swiperSlide {
   text-align: center;

   img {
    width: 162px;
    height: 237px;
    border-radius: 8px;
   }
  }
 }

 .bgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 237px;
  //opacity: 0.2;

  .bgImage {
   width: 100%;
   height: 237px;
   /deep/ .van-image__img {
    object-fit: none;
    object-position: top;
   }
  }
  .bgImage::after {
   content: '';
   position: absolute;
   top: -4px;
   left: 0;
   width: 100%;
   height: 210px;
   background: linear-gradient(to bottom, rgba(245, 245, 245, 1) 0.1%, rgba(245, 245, 245, 0) 98%) no-repeat;
   background-size: 100% 100%;
  }
 }
}
</style>
