<template>
  <div class="horizontalVideoBox" @click="openVideo">
    <div class="coverBox">
      <ImgDecypt
        class="cover"
        :src="handleCover()"
        :key="
          isAcg
            ? videoInfo.verticalCover || videoInfo.horizontalCover
            : videoInfo.cover
        "
      >
        <div class="typeImg" v-if="videoInfo.kind === 7">
          <img class="icon" src="@/assets/png/audio.png" alt="" />
        </div>
        <div class="playNumLabel" v-if="patternType === 1">
          <img class="icon" src="@/assets/png/playNumIcon.png" alt="" />
          <span>{{ videoInfo.countBrowse }}</span>
        </div>
        <div v-else-if="!isAcg">
          <div class="label" v-if="videoInfo.freeArea">
            <img class="icon" src="@/assets/png/freeLabelIcon.png" alt="" />
          </div>
          <div class="label" v-else-if="videoInfo.originCoins > 0">
            <img class="icon" src="@/assets/png/goldLabelIcon.png" alt="" />
          </div>
          <div class="label" v-else-if="videoInfo.originCoins === 0">
            <img class="icon" src="@/assets/png/vipLabelIcon.png" alt="" />
          </div>
        </div>
        <div v-else>
          <div class="label" v-if="videoInfo.permission == 1">
            <img class="icon" src="@/assets/png/goldLabelIcon.png" alt="" />
          </div>
          <div class="label" v-if="videoInfo.permission == 2">
            <img class="icon" src="@/assets/png/freeLabelIcon.png" alt="" />
          </div>
          <div class="label" v-if="videoInfo.permission === 0">
            <img class="icon" src="@/assets/png/vipLabelIcon.png" alt="" />
          </div>
          <div class="total" v-if="!videoInfo.adv">
            <span v-if="videoInfo.updateStatus == 1"
              >更新中·{{ videoInfo.totalEpisode }}话</span
            >
            <span v-else>已完结·{{ videoInfo.totalEpisode }}话</span>
          </div>
        </div>
      </ImgDecypt>
      <div class="videoInfo">
        <div class="title">
          {{ videoInfo.adv ? videoInfo.adv.title : videoInfo.title }}
        </div>

        <div class="desc" v-if="patternType === 1 && !videoInfo.adv">
          章节{{ videoInfo.totalEpisode }}话
        </div>

        <div class="tags" v-if="!isAcg && videoInfo.tags">
          <div
            class="tag"
            v-for="tag in videoInfo.tags.slice(0, 3)"
            :key="tag.id"
          >
            {{ tag.name }}
          </div>
        </div>
        <div class="tags" v-else-if="isAcg && videoInfo.tagDetails">
          <div
            class="tag"
            v-for="tag in videoInfo.tagDetails.slice(0, 3)"
            :key="tag.id"
          >
            {{ tag.name }}
          </div>
        </div>
      </div>
      <!--      <div class="collectBtn">-->
      <!--&lt;!&ndash;        <SvgIcon&ndash;&gt;-->
      <!--&lt;!&ndash;            class="collection"&ndash;&gt;-->
      <!--&lt;!&ndash;            name="redStar"&ndash;&gt;-->
      <!--&lt;!&ndash;            v-if="videoInfo.vidStatus.hasCollected && props.showCollect"&ndash;&gt;-->
      <!--&lt;!&ndash;            @click.stop="clickCollect(videoInfo)"&ndash;&gt;-->
      <!--&lt;!&ndash;        />&ndash;&gt;-->
      <!--&lt;!&ndash;        <SvgIcon&ndash;&gt;-->
      <!--&lt;!&ndash;            class="collection"&ndash;&gt;-->
      <!--&lt;!&ndash;            name="collect"&ndash;&gt;-->
      <!--&lt;!&ndash;            v-else-if="props.showCollect"&ndash;&gt;-->
      <!--&lt;!&ndash;            @click.stop="clickCollect(videoInfo)"&ndash;&gt;-->
      <!--&lt;!&ndash;        />&ndash;&gt;-->
      <!--      </div>-->
      <!--      <div class="collectBtn">-->
      <!--&lt;!&ndash;        <SvgIcon&ndash;&gt;-->
      <!--&lt;!&ndash;            name="selecteds"&ndash;&gt;-->
      <!--&lt;!&ndash;            class="collection"&ndash;&gt;-->
      <!--&lt;!&ndash;            v-if="isSelected && props.showSelected"&ndash;&gt;-->
      <!--&lt;!&ndash;            @click.stop="toggleSelect(videoInfo)"&ndash;&gt;-->
      <!--&lt;!&ndash;        ></SvgIcon>&ndash;&gt;-->
      <!--&lt;!&ndash;        <SvgIcon&ndash;&gt;-->
      <!--&lt;!&ndash;            name="radio"&ndash;&gt;-->
      <!--&lt;!&ndash;            v-else-if="props.showSelected"&ndash;&gt;-->
      <!--&lt;!&ndash;            class="collection"&ndash;&gt;-->
      <!--&lt;!&ndash;            @click.stop="toggleSelect(videoInfo)"&ndash;&gt;-->
      <!--&lt;!&ndash;        ></SvgIcon>&ndash;&gt;-->
      <!--      </div>-->
      <!--      <div class="subscriptInfo" v-if="!videoInfo?.type">-->
      <!--&lt;!&ndash;        <div class="newLabel" v-if="state.isNew"></div>&ndash;&gt;-->
      <!--&lt;!&ndash;        <div class="hotLabel" v-if="state.isHot"></div>&ndash;&gt;-->
      <!--      </div>-->
      <!--      <div class="subscriptInfoAdv" v-else>广告</div>-->
      <!--      <SvgIcon v-if="state.isDelBtn" name="delBtnRed" class="delBtn" @click.stop="emit('onDel', videoInfo)" />-->
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import { jumpAdv } from "@/utils/getConfig";
import store from "@/store";
import { setSessionItem } from "@/utils/longStorage";
import { queryHistoryId } from "@/utils";

export default {
  name: "HorizontalVideoBox",
  props: {
    videoInfo: {
      type: Object,
    },
    videoList: {
      type: Array,
    },
    currentIndex: {
      type: Number,
    },
    isAcg: {
      type: Boolean,
    },
    patternType: {
      type: Number,
    },
  },
  components: {
    ImgDecypt,
  },
  methods: {
    handleCover() {
      if (this.videoInfo.adv) {
        return this.videoInfo.adv.cover;
      } else if (this.isAcg && this.videoInfo.direction == 0) {
        return this.videoInfo.horizontalCover || this.videoInfo.verticalCover;
      } else if (this.isAcg && this.videoInfo.direction == 1) {
        return this.videoInfo.verticalCover || this.videoInfo.horizontalCover;
      } else {
        return this.videoInfo.cover;
      }
    },
    // 转base64
    encodeBase64(str) {
      return Buffer.from(str, "utf-8").toString("base64");
    },
    async openVideo() {
      if (this.videoInfo.adv) {
        jumpAdv(this.videoInfo.adv);
        return;
      }
      if (this.isAcg) {
        let kind = this.videoInfo["kind"];
        if (kind != null) {
          switch (kind) {
            case 0:
            case 2:
            case 3:
            case 6:
              //跳转视频
              this.$router.push({
                path: "/videoDetailsPage",
                query: {
                  id: this.videoInfo.id,
                  isAcg: this.isAcg ? 1 : 0,
                  kind: kind,
                  // fromTagList: this.$route.path == "/tagList" ? true : false,
                },
              });
              break;
            case 8:
              // setSessionItem("currentWorks", JSON.stringify(this.videoInfo));
              // this.$router.push({
              //   path: "/shortVideo"
              // });
              this.$store.commit("video/SET_SHORT_VIDEO_LIST", this.videoList);
              this.$store.commit(
                "video/UPDATE_SHORT_VIDEO_INDEX",
                this.currentIndex
              );
              this.$router.push({
                path: "/shortVideoDetails",
                query: {
                  isAcg: this.isAcg ? 1 : 0,
                },
              });
              break;
            case 4:
            case 1:
              store.commit("pageRefresh/SET_REFRESHFLAG", {
                name: "novelDetailsPage",
                flag: false,
              });
              //小说/漫画
              this.$router.push({
                path: "/novelDetailsPage",
                query: {
                  id: this.videoInfo.id,
                  kind: kind,
                  // fromTagList: this.$route.path == "/tagList" ? true : false,
                  price: this.videoInfo.price,
                },
              });
              break;
            case 5:
              var list = JSON.stringify(this.videoList);
              //图集
              this.$router.push({
                path: "/portrayPage",
                query: {
                  id: this.videoInfo.id,
                  kind: this.videoInfo.kind,
                  list: this.encodeBase64(list),
                  phoneIndex: this.currentIndex,
                  // fromTagList: this.$route.path == "/tagList" ? true : false,
                },
              });
              break;
            case 7:
              try {
                // 获取历史记录的最大子集信息
                let historyPostInfo =
                  (await queryHistoryId(this.videoInfo.id)) || 0;
                // 跳转到有声小说详情页
                this.$router.push({
                  path: "/audioBookDetails",
                  query: {
                    id: this.videoInfo.id,
                    kind: this.videoInfo.kind,
                    price: this.videoInfo.price,
                    itemIndex: historyPostInfo.historyIndex || 0,
                  },
                });
              } catch (error) {
                console.error(error);
              }
              break;
          }
        }
      } else {
        if (this.videoInfo.newsType === "COVER") {
          this.$router.push({
            path: "/galleryDetails",
            query: {
              vid: this.videoInfo.id,
            },
          });
        } else {
          if (this.videoInfo.playTime > 300) {
            this.$router.push({
              path: "/videoDetailsPage",
              query: {
                id: this.videoInfo.id,
                isAcg: this.isAcg ? 1 : 0,
                kind: 9,
              },
            });
          } else {
            this.$store.commit("video/SET_SHORT_VIDEO_LIST", this.videoList);
            this.$store.commit(
              "video/UPDATE_SHORT_VIDEO_INDEX",
              this.currentIndex
            );
            // setSessionItem("currentWorks", JSON.stringify(this.videoInfo));
            this.$router.push({
              path: "/shortVideoDetails",
              query: {
                isAcg: this.isAcg ? 1 : 0,
              },
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.horizontalVideoBox {
  height: 100%;
  width: 100%;

  .coverBox {
    //height: 94px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0px);
    background: #ffffff;

    .cover {
      height: 98px;
      position: relative;
      border-radius: 8px;
      /deep/ .van-image__img {
        border-radius: 8px;
      }
      .typeImg {
        position: absolute;
        bottom: 6px;
        left: 6px;
        z-index: 1;
        width: 16px;
        height: 16px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 13px;
          height: 13px;
        }
      }

      .playNumLabel {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
        color: #ffffff;
        font-size: 10px;
        font-weight: 400;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 2px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        .icon {
          width: 10px;
          height: 10px;
          margin-right: 3px;
        }
      }

      .domesticNew {
        width: 34px;
        height: 16px;
        position: absolute;
        top: 8px;
        left: 0;
      }
      .label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        .icon {
          width: 32px;
          height: 20px;
        }
      }
      .total {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 1;
        padding: 0 9px;
        height: 20px;
        text-align: center;
        border-radius: 11px;
        background: rgba(255, 255, 255, 0.7);
        span {
          font-size: 12px;
          font-weight: 500;
          color: #3e169d;
        }
      }
    }
    .videoInfo {
      height: 30px;
      //width: 100%;
      //background: linear-gradient(to bottom, rgba(14, 20, 30, 0), rgba(14, 20, 30, 0.69));
      ////position: absolute;
      ////left: 0;
      ////bottom: 0;
      ////padding: 0 6px;
      ////transform: translateZ(0px);
      //font-size: 11px;
      //color: white;
      padding: 5px 5px 10px 5px;

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
        font-size: 12px;
        font-weight: 600;
      }

      .desc {
        color: #999;
        font-size: 10px;
        font-weight: 400;
        margin-top: 4px;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        font-size: 10px;
        color: #999;
        font-weight: 600;
        margin-top: 5px;
        height: 16px;

        .tag {
          margin-right: 6px;
        }
      }

      .playCount {
        > svg {
          height: 14px;
          width: 14px;
          margin-right: 2px;
        }
      }
    }
    .delBtn {
      color: white;
      height: 18px;
      width: 18px;
      position: absolute;
      right: 6px;
      bottom: 6px;
      z-index: 10;
    }
    .subscriptInfo {
      position: absolute;
      top: 8px;
      left: 0;
      z-index: 1;
      height: 16px;
      width: 36px;
      border-radius: 0 0 4px 0;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      line-height: 16px;
      //.newLabel {
      //  height: 16px;
      //  width: 36px;
      //  background: url('@/assets/images/png/home/newLabel.png') no-repeat;
      //  background-size: 100% 100%;
      //}
      //.hotLabel {
      //  height: 16px;
      //  width: 36px;
      //  background: url('@/assets/images/png/home/hotLabel.png') no-repeat;
      //  background-size: 100% 100%;
      //}
    }
    .subscriptInfoAdv {
      transform: translateZ(0px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 16px;
      width: 34px;
      border-radius: 0 0 4px 0;
      font-size: 11px;
      font-weight: 500;
      color: rgb(0, 0, 0);
      background-color: rgb(255, 235, 58);
      text-align: center;
      line-height: 16px;
    }
  }
}
</style>
