<!--  三屏滑动(竖)-- -->
<template>
  <div class="threeSlideVertical">
    <div class="titleBox">
      <div class="leftBox">
        <div class="title">
          {{ section.sectionName }}
        </div>
        <div class="desc" v-if="section.sectionDesc">
          {{ section.sectionDesc}}
        </div>
      </div>
      <div class="rightBtn" @click="moreBtn">
        <template v-if="moreBtnTxt">
          <span></span>
          <span>{{moreBtnTxt}}</span>
        </template>
        <template v-else>
          <span>更多</span>
          <img class="icon" src="@/assets/png/rightSubjectIcon.png" alt="">
        </template>
      </div>
    </div>
    <div class="rowsBox" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo)" @touchstart.stop @touchmove.stop @touchend.stop>
      <HorizontalVideoBox
          class="videoBox"
          :isAcg="isAcg"
          :patternType="patternType"
          :currentIndex="index"
          :videoInfo="item"
          :videoList="(isAcg?section.allMediaInfo:section.allVideoInfo)"
          v-for="(item,index) in (isAcg?section.allMediaInfo:section.allVideoInfo)" :key="item.id"
      />
    </div>

    <div class="btnBoxs" v-if="(isAcg?section.allMediaInfo:section.allVideoInfo) && isBtn">
      <div class="btnBox">
        <div class="btn" @click="moreBtn">
          <img class="icon" src="@/assets/png/moreVideoIcon.png" alt="">
          <span>更多片源</span>
        </div>
        <div class="halvingLine">

        </div>
        <div class="btn" @click="changeVideo">
          <img class="icon" src="@/assets/png/switchIcon.png" alt="">
          <span>更换一批</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import {jumpAdv} from "@/utils/getConfig";
import {queryChangeVideoList} from "@/api/app";
import {Toast} from "vant";

export default {
  name: "ThreeSlideVertical",
  components:{
    HorizontalVideoBox
  },
  props:{
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn:{
      type: Boolean,
    },
    moreBtnTxt:{
      type: String,
    },
    patternType:{
      type: Number,
    }
  },
  methods:{
    moreBtn(){
      if (this.section.linkUrl) {
        jumpAdv(this.section);
      } else {
        this.$router.push({
          path: "/topicPage",
          query: {
            topicId: this.section.sectionID,
            name: this.section.sectionName,
            isAcg: this.isAcg ? 1 : 0,
            patternType: this.patternType || 0,
          },
        });
      }
    },
    async changeVideo() {
      let res = await this.$Api(queryChangeVideoList, this.section.sectionID);
      const list = res.data.videos || [];
      if(list.length > 0){
        if(this.isAcg){
          this.section.allMediaInfo = list;
        }else{
          this.section.allVideoInfo = list;
        }
      }else{
        Toast('没有更多数据了~');
      }
    }
  }
}
</script>
<style scoped lang="scss">
.threeSlideVertical{
  margin-top: 20px;
  .titleBox {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftBox{
      display: flex;
      flex-direction: column;

      .title{
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }

      .desc{
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin-top: 10px;
      }

    }

    .rightBtn{
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 12px;

      .icon{
        width: 20px;
        height: 20px;
      }
    }
  }
  .rowsBox {
    margin-top: 12px;
    padding: 0 10px;
    display: flex;
    overflow-x: auto;
    margin-right: 5px;

    .videoBox{
      margin-right: 9px;
    }
  }

  .btnBoxs{
    width: 100%;
    height: 38px;
    padding: 0 21px;
    margin-top: 16px;

    .btnBox{
      width: 333px;
      height: 38px;
      background: #F0F0F3;
      border-radius: 20px;
      box-shadow: -5px -5px 20px 0px #FFF, 5px 5px 20px 0px rgba(174, 174, 192, 0.50);

      display: flex;
      align-items: center;
      justify-content: center;

      .halvingLine{
        width: 1px;
        height: 38px;
        background: #CBCED1;
        margin: 0 45px;
      }

      .btn{
        display: flex;
        align-items: center;
        color: #B09FD9;
        font-size: 10px;
        font-weight: 900;

        .icon{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

/deep/ .horizontalVideoBox{
  .coverBox{
    width: 111px;
    .cover{
      height: 162px;
    }
  }
}

</style>