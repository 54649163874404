<template>
  <div class="originalSwiper">
    <div class="head">
      <div class="swiperBox" @touchstart.stop @touchmove.stop @touchend.stop>
        <div class="carousel" v-if="mediaInfo.length">
          <div
              class="carousel-item"
              @touchstart="handleTouchstart"
              @touchmove="handleTouchmove"
              @touchend="handleTouchend"
              v-for="(item, index) in computedItems"
              @click.stop="openDetails(mediaInfo, mediaInfo[index], index)"
              :key="index"
              :style="{
                    transform: `scale(${item.scale}) translate(${item.translateX}px, ${item.translateY}px)`,
                    zIndex: item.zIndex,
                    width: `${item.width}px`,
                    height: `${item.height}px`,
                  }"
          >
            <ImgDecypt
                :src="mediaInfo[currentIndex].verticalCover ? mediaInfo[index].verticalCover : mediaInfo[index].horizontalCover"
                :key="item.id"
                :style="{ width: '100%', height: '100%' }"
                :alt="'ImageError ' + (index + 1)"
            />
            <img v-if="index === currentIndex" class="originalPlay" src="@/assets/png/originalPlay.png" alt="">
          </div>
          <div class="carousel-indicators">
              <span
                  v-for="(item, index) in items"
                  :key="index"
                  :class="{ active: index === currentIndex }"
              ></span>
          </div>
        </div>
        <div class="bgBox" :style="backgroundStyle">
          <ImgDecypt
              class="bgImage"
              :src="mediaInfo[currentIndex].verticalCover ? mediaInfo[currentIndex].verticalCover : mediaInfo[currentIndex].horizontalCover"
              :key="currentIndex"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import {openDetails} from "@/utils/getConfig";

export default {
  name: "VideoModuleItem",
  components: {
    ImgDecypt
  },
  props: {
    section: {
      type: Object,
    },
    isAcg: {
      type: Boolean,
    },
    isBtn:{
      type: Boolean,
    }
  },
  data() {
    return {
      startX: 0, // 触摸开始时的X坐标
      currentX: 0, // 当前轮播图的X坐标
      backgroundImage: "",
      decyImg: [],
      currentIndex: 0,
      items: [
        {
          width: 189,
          height: 276,
          scale: 1,
          translateX: 0,
          zIndex: 4,
        },
        {
          width: 124,
          height: 181,
          scale: 0.5,
          translateX: 50,
          zIndex: 3,
        },
        {
          width: 80,
          height: 116,
          scale: 0.4,
          translateX: 100,
          zIndex: 2,
        },
        {
          width: 50,
          height: 78,
          scale: 0.3,
          translateX: 150,
          zIndex: 1,
        },
      ]
    }
  },
  mounted() {
    // this.imgDecypt();
    setInterval(this.next, 4000);
  },
  computed:{
    mediaInfo() {
     return this.isAcg ? this.section.allMediaInfo : this.section.allVideoInfo;
    },
    // 轮播图
    computedItems() {
      let tempIndex = this.currentIndex;
      let prevIndex = (tempIndex - 1 + this.items.length) % this.items.length;

      return this.items.map((item, index) => {
        let offset = index - tempIndex;
        if (offset < 0) offset += this.items.length;

        let firstTranslateY = index === tempIndex ? -15 : 0;
        let secondTranslateY =
            index === (tempIndex + 1) % this.items.length ? -5 : 0;
        let extraTranslateX = index === prevIndex ? 25 : 0;
        let endY = firstTranslateY + secondTranslateY;

        return {
          ...item,
          scale: 1 - 0.1 * offset,
          translateX: 110 * offset + 10 * (offset - 1) + extraTranslateX,
          translateY: endY,
          zIndex: this.items.length - offset,
          width: 197 - 31 * offset,
          height: 262 - 52 * offset,
        };
      });
    }
  },
  methods: {
   openDetails,
    backgroundStyle() {
     return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
    handleTouchstart(event) {
     this.startX = event.touches[0].clientX;
    },
    handleTouchmove(event) {
      const dx = event.touches[0].clientX - this.startX; // 计算滑动距离
      this.currentX += dx; // 更新轮播图位置
      this.startX = event.touches[0].clientX; // 更新触摸位置
    },
    handleTouchend() {
      if (this.currentX > 0) {
        this.next();
      } else if (this.currentX < 0) {
        this.previous();
      }
      this.currentX = 0; // 重置currentX
    },
    // 轮播图下一张
    next() {
      // this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      // this.updateItems();
    },
    // 轮播图上一张
    previous() {
      // this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex =
          (this.currentIndex - 1 + this.items.length) % this.items.length;
    }
  },
}
</script>
<style scoped lang="scss">
  .originalSwiper{


    .head {
      width: 100%;
      height: 290px;
      position: relative;
    }


    .swiperBox {
      height: 300px;
      padding: 0px 16px;

      .carousel {
        position: relative;
        width: 100%;
        height: 280px;
      }

      .bgBox {
        position: absolute;
        top: 0;
        left: 0;
        //width: 100%;
        //height: 210px;
        //background-repeat: no-repeat;
        //background-size: cover;
        width: 375px;
        height: 276px;
        opacity: 0.2;

        .bgImage{
          width: 375px;
          height: 276px;
        }
      }

      //.bgIMage::before {
      //  content: "";
      //  position: absolute;
      //  bottom: 0;
      //  left: 0;
      //  width: 100%;
      //  height: 210px;
      //  background: linear-gradient(
      //          180deg,
      //          rgba(26, 26, 26, 0) 0%,
      //          #1b1b1b 80%
      //  );
      //  background-repeat: no-repeat;
      //  background-size: cover;
      //}

      .carousel-item {
        position: absolute;
        // width: 100% !important;
        height: 100%;
        bottom: 0;
        left: 6px;
        border-radius: 8px;
        transition: transform 1s, width 1s, height 1s;

        /deep/ .van-image__img {
          border-radius: 8px;
        }

        /deep/ .img-decypt {
          z-index: 99999 !important;
        }
      }

      .originalPlay {
        position: absolute;
        right: 10px;
        bottom: 13px;
        width: 30px;
        height: 30px;
      }

      .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 1px;
        width: 100%;
        text-align: right;
      }

      .carousel-indicators span {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 2px;
        background: #B09FD9;
        opacity: 0.5;
        border-radius: 50%;
      }
      .carousel-indicators span.active {
        width: 12px;
        border-radius: 20px;
        background: #B09FD9;
        opacity: 1;
      }
    }
  }
</style>
